export const portalData = {
  // Fax Details
  hasFax: false,
  faxNo: "09-6284168",
  
  // Whatsapp details
  hasWhatsApp: true,
  whatsAppNo: "+6012 772 9880",
  whatsAppLinkNo: "0127729880",
  whatsAppNoHolderName: "Trace Lai",
  hasAltWhatsApp: false,
  altWhatsAppNo: "",
  altWhatsAppNoHolderName: "",
  hasAltWhatsApp1: false,
  altWhatsAppNo1: "",
  altWhatsAppNoHolderName1: "",
  hasAltWhatsApp2: false,
  altWhatsAppNo2: "",
  altWhatsAppNoHolderName2: false,
  
  // Phone number details
  hasPhoneNo: true,
  phoneNo: "09 - 419 7788",
  hasAltPhoneNo: false,
  altPhoneNo: true,
  
  // Telephone number details
  hasTellNo: true,
  tellNo: "+6012 772 9880",
  tellNoHolderName: "Trace Lai",
  hasAltTellNo: true,
  altTellNo: "014 354 1593",
  altTellNoHolderName: "",
  hasAltTellNo2: false,
  altTellNo2: "",
  altTellNoHolderName2: "",
  
  // Portal information details
  portalNameXSpace: "Aman Tioman Beach",
  portalName: "Aman Tioman Beach",
  portalNameUppercase: "AMAN TIOMAN BEACH",
  portalFullName: "Aman Tioman Beach Resort Sdn Bhd",
  portalFullNameUppercase: "AMAN TIOMAN BEACH RESORT SDN BHD",
  portalShortAddress: "Pahang Darul Makmur",
  portalAddress: "Lot 73, Kampung Paya, Pulau Tioman, 26800 Pahang Darul Makmur",
  portalAddressFirstPart: "",
  portalAddressSecondPart: "",
  portalAddressDetails: "",
  hasPortalRegNo: false,
  portalRegNo: "",
  portalBoatDropLocation: "",
  
  // Portal Bank Details
  portalBankName: "CIMB Bank Berhad",
  portalBankAddress: false,
  portalBankAccHolderName: "Aman Tioman Beach Resort Sdn Bhd",
  portalBankAccNo: "8009170346",
  hasPortalBankSwiftCode: true,
  portalBankSwiftCode: "CIBBMYKL",
   
  portalEmail: "sales@amantioman.com",
  portalTermAndCondUrl: process.env.REACT_APP_PORTAL_TERM_AND_COND_URL,
  
  appName: "Tioman Digital Intelligence Platform (TBDIP)",

  // GST details
  hasGst: false,
  gstNo: true,
   
  // Website
  portalWebsite: "https://www.amantioman.com",
  portalWebLinkText: "amantioman.com",
  portalPromotionPage: "https://www.amantioman.com/promotion/",
  portalTermAndCond: "https://www.amantioman.com/terms-and-conditions",
 
  // Travoxis Website
  travoxisWebsite: process.env.REACT_APP_TRAVOXIS_WEBSITE,
 
  // Facebook page link
  facebookMessengerLink: "https://www.facebook.com/amantiomansales/",
 
  // portal S3 base url
  s3AwsUrl: "",
 
  adminPortalUrl: process.env.REACT_APP_ADMIN_PORTAL_URL,

  // Portal Jetty names
  jettyOne: "Mersing Jetty",
  jettyTwo: "Jetty Paya",
  fromLocation: "Pahang Darul Makmur",
  jettyThree: "",
  jettyOneToTwoTime: "",
  jettyTwoToOneTime: "",
}